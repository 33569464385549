<template xs>
  <v-parallax :src="require('../../assets/editor1.jpg')">
  <br>
   
          
            
            
            
          
    <v-row align="center"  xs>
      <v-col align="center"  v-for="(item, i) in items" :key="i" cols="12" xs1>
      <br>
      <br>
       
        <v-divider></v-divider>
        <v-icon
						large
            class="text-md-center"
						:color="socialmedia.color"
					 
						>
						{{socialmedia.icono}}
						</v-icon>
              <v-divider></v-divider>
        <!-- <span v-html="socialmedia.url">
        
        </span> -->
        <!-- <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/DDN7h55TJ38" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </v-col>
    </v-row>
     <br>
       <br>
         
  </v-parallax>
</template> 
<script>
export default {
  data: () => ({
    socialmedia:[],
    items: [

      {
        color: 'blue lighten-3',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'Live - Radio Celestial Omega',
        artist: 'Radio - Live',
      },
    ],
  }),
  mounted() {
    //this.cargarGaleria();
  },
  methods: {
       cargarGaleria() {
           
            
             let that = this;
             var url = "https://radiocelestialadmin.codevesolut.com/api/live";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.socialmedia = response.data.lives
               // console.log(that.socialmedia);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
  },
}
</script>
<style>
.transparent {
  background-color: white !important;
  opacity: 0.65;
  border-color: transparent !important;
}
.video {
  opacity: 100;
}
</style>
