<template>
 
    

    
        <v-container fluid :height='height'>
             
               <v-row>
        <v-col cols="12">
            
         <v-slide-group
     
      class="pa-4"
      active-class="success"
      show-arrows
    >
      <v-slide-item
      v-for="(item, index) in listasSeguros" :key="index"
        v-slot="{ active, toggle }"
      >
        <v-card
          elevation="12"
          class="ma-4"
          height="100"
          width="200"
          @click="toggle"
        >
          
            <v-scale-transition>
             <v-img
                        contain
                            :src="item.src"
                            class="white--text align-end"
                            
                            height="100"
          width="200"
                            
                        >
                          
                            
                         
                        </v-img>
            </v-scale-transition>
       
        </v-card>
      </v-slide-item>
    </v-slide-group>
           
        </v-col>

      </v-row>

             
            <br>
            <br>
        </v-container>
        
     
 


    
 
</template>

 

 
<script>

import auth from '../../../mixins/auth';

export default {
    data: () => ({
         url_:"",
         listasSeguros:[
            //  {nombre:"ASIKEN",src:require('../../../assets/asisken.jpg')},
            //  {nombre:"B.M.I",src:require('../../../assets/bmi.png')},
            //  {nombre:"HUMANA",src:require('../../../assets/humana.png')},
            //  {nombre:"LATINA",src:require('../../../assets/latina.jpg')},
            //  {nombre:"CONFIAMED",src:require('../../../assets/descargar.png')},
            //  {nombre:"PLAN VITAL",src:require('../../../assets/planvital.jpg')},
            //  {nombre:"SALUD SA",src:require('../../../assets/saludsa.png')},
            //  {nombre:"MEDEC",src:require('../../../assets/medec.jpg')},

         ],
        page:1,
      length:2,
       users: null,
       pagination: {
                current: 1,
                total: 0
            },
        galerias: [],
        galerias_imagenes:[],
        attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),

    mixins: [auth],

     methods: {
        cargarGaleria() {
            
            
             let that = this;
            var url =  this.url_ +"api/seguro";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                   that.listasSeguros = response.data.seguros;
                  
                   // that.galerias = response.data.galerias
               
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
            },
             onPageChange() {
            this.cargarGaleria();
        },
        
  },
  mounted() {
       this.url_ = this.$store.state.link;
      this.cargarGaleria();
      //this.onPageChange();
  },
  computed: {
      
       height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    
 
  }
};
</script>
