import Vue from 'vue'
import App from './components/home/Index'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VuetifyAudio from './components/vuetifyaudio.vue'
import axios from 'axios';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
window.axios = require('axios');
Vue.component("vue-radio", VuetifyAudio);
Vue.config.productionTip = false
Vue.use(VueTelInputVuetify, {
    vuetify,
});


const cors = require("cors");
const corsOptions = {
        origin: '*',
        credentials: true, //access-control-allow-credentials:true
        optionSuccessStatus: 200,
    }
    //app.use(cors(corsOptions))

new Vue({

    router,
    store,
    vuetify,
    corsOptions,
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1280,
        },
        scrollBarWidth: 24,
    },
    render: h => h(App)
}).$mount('#app')