<template>
 
  
    <div >
      

 
 
  <slider-home></slider-home>
      <br>
      
     <v-divider></v-divider>
 
            <especialidades> </especialidades> 
         <br>
      <v-divider></v-divider>
        <br> 
     <home-parallax></home-parallax>
      <br>

      <!-- <v-divider></v-divider> -->
        <!-- <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">Con quienes trabajamos</h1> 
            
          </v-card-text>
        </v-card> -->
        <br>
       <v-divider></v-divider>
        <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">Títulos Obtenidos</h1> 
            
          </v-card-text>
        </v-card>
        <br>
      <v-divider></v-divider> 
      <br>
    <lista-titulos></lista-titulos>
       <br>
    

      <v-divider></v-divider>
        <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">Actualizaciones Médicas</h1> 
            
          </v-card-text>
        </v-card>
        <br>
      <v-divider></v-divider> 
     <!-- <home-video-face   > </home-video-face> -->
    <br>
    <lista-galeria></lista-galeria>
       <br>

      <v-divider></v-divider>
        <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">
            
            <v-btn icon>
            <v-icon size="30px" color="light-blue" >mdi-human-child</v-icon>
            </v-btn></h1> 
            
          </v-card-text>
        </v-card>
        <br>
      <v-divider></v-divider> 
     <!-- <home-video-face   > </home-video-face> -->
    <br>
    <lista-personas></lista-personas>
     <!-- <home-video-you></home-video-you> -->
     <!-- <affiliates> </affiliates> 
     <social-media> </social-media>  -->
     <br>
       <v-divider></v-divider>
        <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">Seguros Disponibles</h1> 
            
          </v-card-text>
        </v-card>
        <br>
      <v-divider></v-divider> 
    <lista-seguros></lista-seguros>
       <br>
     </div>
</template>

<script>
   import ListaGaleria from '../sections/galeria/ListaCertificados'
   import ListaSeguros from '../sections/galeria/ListasSeguros'
   import ListaTitulos from '../sections/galeria/ListadoTitulo'


   import ListaPersonas from '../sections/galeria/ListaPersonas'

  import SliderHome from '../sections/SliderHome'
  import HomeParallax from '../sections/HomeParallax'
 
 
 
   import Especialidades from '../sections/Especialidades'

  export default {

  name: 'PageIndex',
  data() {
    return {
      vtext:'',
      valuecarlos:'',
      valuejonu:'',
      form:{
        id:'',
      }
    }
  },
  components: {  
       SliderHome,
    HomeParallax,
   ListaGaleria,
    Especialidades,
    ListaPersonas,
    ListaSeguros,
    ListaTitulos,
    },
  mounted () {
    // window.Echo = new Echo({
    //   broadcaster: 'pusher',
    //   key: 'ASDASD2121',
    //   wsHost: window.location.hostname,
    //   wsPort: 6001,
    //   disableStats: true,
    //   //enabledTransports: ['ws', 'wss'], // <-- only use ws and wss as valid transports
    //    forceTLS: false,
    // })
    // let that = this;
    // window.Echo.channel('home').listen('NewMessage', (e) => {

    //   that.vtext = e.message;
    //   console.log(e.message)
    // })
  },
  methods: {
    saludar()
        {
          // axios.post('/user', {
          //   firstName: 'Fred',
          //   lastName: 'Flintstone'
          // })
          // .then(function (response) {
          //   console.log(response);
          // })
          // .catch(function (error) {
          //   console.log(error);
          // });
        // let that = this;
        //  let url ='http://192.168.8.73:8000/api/saluda2/';

        //             axios.post(url,this.form)
        //             .then(function(response) {
        //                  that.vtext = esponse.data.saludo;
        //                 console.log(response.data.saludo) 
                         
        //             })
        //             .catch(error => {
        //                 Errores de validación
                          
                        
        //             });
        //post('http://192.168.8.73:8000/api/saluda2/'+this.form)
        // const data = await fecth('http://192.168.8.73:8000/api/saluda2/'+this.form)
        // const response = await data.json()
        //console.log(response.saludo)
        // this.vtext = response.saludo;
       
    }
  }
}
  
</script>
 