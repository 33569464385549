import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // http://api.drnixonrivas.com/api/articulo
        link: "https://api.drnixonrivas.com/",
    },
    mutations: {},
    actions: {},
    modules: {}
})