import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Home2 from '../views/home/Index.vue'

Vue.use(VueRouter)

const routes = [{
        path: '',
        name: 'Home',
        component: Home2
    },
    {
        path: '/',
        name: 'Inicio',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/home/Index.vue')
    },
    {
        path: '/galeria',
        name: 'Galeria',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/galeria/Index.vue')
    },
    {
        path: '/articulos',
        name: 'Articulos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/eventos/Index.vue')
    },
    {
        path: '/congresos',
        name: 'Congresos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/programas/Index.vue')
    },
    {
        path: '/ubicacion',
        name: 'Ubicación',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/ubicacion/Index.vue')
    },
    {
        path: '/agenda',
        name: 'Agendar Citas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/agenda/Index.vue')
    },
    {
        path: '/articulos_all',
        name: 'articulos_all',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            //import ( /* webpackChunkName: "about" */ '../views/About.vue')
            import ( /* webpackChunkName: "about" */ '../views/sections/eventos/Articulos.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router