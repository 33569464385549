<template>
    <div>
        
 

            <v-carousel
                cycle
         height="auto"
            hide-delimiter-background
            show-arrows-on-hover
                    >
                    <v-carousel-item
                    v-for="(item,i) in items"
                    :key="i"
                   
                     :src="item.src"
                     max-height="600"
                    >
   
                  
                    </v-carousel-item>
            </v-carousel>
            <br>
            <!-- </v-banner> -->
           <v-divider></v-divider>
      <v-card>
        <v-card-text
         align="center"
            justify="center">

          
          <h1 class="text-md-center">{{ informacion.nombre_gerente }} - {{ informacion.servicio_medico }} </h1> 
          <br>
          <p class="text-md-center">
          <b>
          {{ informacion.experiencia_laboral }}
          </b>
          </p>
          
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
       <br>   
      </div>
 
</template>

<script>
export default {
    name: "SectionHero",
        data () {
            return {
               url_:"",
                 items: [],
                colors: [
                'indigo',
                'warning',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
                ],
                // informacion:{
                //     nombre_gerente:"",
                //     experiencia_laboral:"",
                //     servicio_medico:"",
                //     descripcion_personal:"",
                //     nombre_empresa:"",
                //     direccion_empresa:"",
                //     direccion_oficina:"",
                //     convencional:"",
                //     celular:"",
                //     correo_electronico:"",
                //     google_map:"",
                //     src:"",
                                   

                // },
                informacion:[],
                slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
                ],
            }
            },
    provide: {
        // theme: { isDark: true }
    },
    methods: {
          cargarGaleria() {
          //  console.log('holis');
            
             let that = this;
              var url =  this.url_ +"api/carrusel";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.items = response.data.carruseles
             //  console.log(that.items);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
        cargarInformacion() {
           
            
             let that = this;
              var url =  this.url_ +"api/empresa";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.informacion = response.data.empresa;
               // console.log(that.informacion);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
    },
    mounted() {
        this.url_ = this.$store.state.link;
         this.cargarGaleria();
         this.cargarInformacion();
    },

    computed: {
        minHeight() {
            const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

            return `calc(${height} - ${this.$vuetify.application.top}px)`;
        }
    }
};
</script>
