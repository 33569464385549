<template>
<div>
  
 
   <v-row justify="space-around">
      <v-col
      v-for="(item, index) in guias" :key="index"
       
        cols="12"
        md="3"
      >
        <v-sheet
          rounded="xl"
          elevation="2"
          class="pa-2"
          color="grey lighten-3"
        >
           
           <v-card
           rounded="xl"
    class="mx-auto"    
    max-height="500"
    
  >
  <v-card-title primary-title class="justify-center" >
   <v-img class="justify-center"
   contain
      :src="item.src"
       max-width="150"
    max-height="150"
      
    ></v-img>
  </v-card-title>
    

    <v-card-title class="justify-center">
      {{ item.titulo }}
    </v-card-title>

    <v-card-subtitle>
    <span style="white-space: pre-line;font-size:20px;" > {{ item.sub_titulo }}   </span>   
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        color="orange lighten-2"
        text
      >
        <!-- Ver más articulos -->
      </v-btn>

      <v-spacer></v-spacer>
<!-- 
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn> -->
    </v-card-actions>

    <!-- <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
        </v-card-text>
      </div>
    </v-expand-transition> -->
  </v-card>
       
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    data: () => ({ 
      url_:"",
      value: 1,
    guias:[],
    ingreso:{
        titulo:"",
        sub_titulo:"",
        src:"",
     }, 
    }),
 methods: {
   
          cargarGaleria() {
          //  console.log('holis');
         //   console.log('cargo');
            
             let that = this;
             var url =  this.url_ + "api/guia";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                 

                  for (let index = 0; index <  response.data.guias.length; index++) {
                   // const element = array[index];
                //   console.log(response.data.guias[index].titulo);   
                 let objeto={};     
                               objeto.titulo=response.data.guias[index].titulo;
                                objeto.sub_titulo=response.data.guias[index].sub_titulo;
                                objeto.src=response.data.guias[index].src;
                                if ( response.data.guias[index].guia_id == "1" ) {
                                   objeto.icono="mdi-human-child"; 
                                         
                                  
                                }
                                 if ( response.data.guias[index].guia_id == "2") {
                                  objeto.icono="mdi-book"; 
                                  
                                } 
                                 if ( response.data.guias[index].guia_id == "3") {
                                  objeto.icono="mdi-book"; 
                                   
                                 } 

                             that.guias.push(objeto);      
                      //  console.log(index);
                             
                  }
                 
                //console.log(that.guias);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
        cargarInformacion() {
           
            
             let that = this;
            var url =  this.url_ +"/api/empresa";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.informacion = response.data.empresa;
              //  console.log(that.informacion);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
              CargarRedes() {
           
            
             let that = this;
               var url =  this.url_ +"api/red_social";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.icons = response.data.redes_sociales;
              //  console.log(that.icons);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
    },
    mounted() {
       this.url_ = this.$store.state.link;

         this.cargarGaleria();
         
         //console.log(this.url_);
         
        //  this.cargarInformacion();
        //  this.CargarRedes();
    },

    computed: {
      color () {
        switch (this.value) {
          case 0: return 'light-blue'
          case 1: return 'indigo darken-3'
          case 2: return 'light-blue darken-4'
          case 3: return 'teal accent-3'
          default: return 'blue-grey'
        }
      },
    },
  }
</script>