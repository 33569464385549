<template>
 
    

    
        <v-container fluid :height='height'>
            <v-row dense>
                <v-col
                    v-for="galeria in users"
                    :key="galeria.img_titulo_obtenido_id"
                     
                    
                >
                    <v-card
                     class="mx-auto"
                    max-width="344"
                     elevation="12"
                   rounded="xl"
                     >
                        <v-img
                            :src="galeria.src"
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            height="250"
                            
                        >
                            <v-card-title v-text="galeria.titulo"></v-card-title>
                            <!-- <div style="margin-bottom: 5px; margin-right: 5px" class="text-right"> -->
                                <!-- <v-avatar v-if="isAuthenticated && user.perfil" :color="galeria.status?'green':'red'" size="36">
                                    <span class="white--text text-h5">{{galeria.status?'A':'I'}} </span>
                                </v-avatar> -->
                            </div>
                        </v-img>

                        <v-card-text  >
                            
                                {{ galeria.sub_titulo.slice(0, 30) }}..
                           
                        </v-card-text>
                        
                        <v-divider></v-divider>
                       <v-card-actions class="justify-center">
                       <!-- aqui va el modal -->
                         <v-row  class="justify-center" >
                                <v-col >
                                

                                <v-dialog
                                    transition="dialog-bottom-transition"
                                   
                                    
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                     class="justify-center" 
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                        

                                    >Galería</v-btn>
                                    </template>
                                    <template v-slot:default="dialog">
                                     
                                    <v-card class="text-center mx-auto"
                                    >
                                       
                                        <v-card-text  >

                                         
                                           
                                            <v-img 
                                         contain
                                         max-height="800"
                                          :src="galeria.src">
                                            </v-img>

                                             
                                             
                                     
                                         
                                        </v-card-text>
                                        <v-card-text>
                                             
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                        <v-btn
                                            text
                                            @click="dialog.value = false,galerias_imagenes=[]"
                                        >Cerrar</v-btn>
                                        </v-card-actions>
                                        
                                               
                                       
                                    </v-card>
                                      
                                    </template>
                                </v-dialog>
                              
                                </v-col>

                                
                            </v-row>
                       <!-- aqui finaliza -->
                      
                        
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
                <br>
                <v-card>
                        <v-card-actions class="justify-center">
                           <v-pagination 
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="onPageChange">
                           </v-pagination>     
                        </v-card-actions>
                         
                </v-card>
            <br>
            <br>
        </v-container>
        
     
 


    
 
</template>

 

 
<script>

import auth from '../../../mixins/auth';

export default {
    data: () => ({
         url_:"",
        page:1,
      length:2,
       users: null,
       pagination: {
                current: 1,
                total: 0
            },
        galerias: [],
        galerias_imagenes:[],
        attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),

    mixins: [auth],

     methods: {
        cargarGaleria() {
            
            
             let that = this;
            var url =  this.url_ +"api/img_titulo_obtenido?page="+this.pagination.current;
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                   that.users = response.data.imgs_titulos_obtenidos.data;
                    that.pagination.current = response.data.imgs_titulos_obtenidos.current_page;
                    that.pagination.total = response.data.imgs_titulos_obtenidos.last_page;
                   // that.galerias = response.data.galerias
               // console.log(that.users );
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
            },
             onPageChange() {
            this.cargarGaleria();
        },
        
  },
  mounted() {
       this.url_ = this.$store.state.link;
      this.cargarGaleria();
      //this.onPageChange();
  },
  computed: {
      
       height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    
 
  }
};
</script>
